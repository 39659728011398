import axios from '@/plugins/axios'
import { savePost } from '../utils'

export default {
  index () {
    return axios.get(`/admin/promos`)
  },
  sendData(form) {
    let body = new FormData
    body.append('name', form.name)
    if (form.image) body.append('image', form.image)
    body.append('enabled', form.enabled)

    if (!form.id) {
      return savePost('/admin/promos', body)
    } else {
      return savePost(`/admin/promos/${form.id}?_method=put`, body)
    }
  }
}
