import axios from 'axios'
import store from '@/store'
import router from '@/router'
import swal from 'sweetalert2'

const baseURL = process.env.VUE_APP_BASE_API

const instance = axios.create({
  baseURL,
  withCredentials: true,
  headers: { 'X-Requested-With': 'XMLHttpRequest' }
})

// Request interceptor
instance.interceptors.request.use(
  request => {
    store.commit('setLoading', true)

    return request
  },
  error => {
    store.commit('setLoading', false)

    return error
  }
)
const ignoreMessages = [
  '/admin/login',
  '/admin/logout',
  '/admin/payments/check-va',
  '/admin/upload',
]

// Response interceptor
instance.interceptors.response.use(
  response => {
    store.commit('setLoading', false)

    if (response.config.method == 'post' &&
      !ignoreMessages.includes(response.config.url))
    {
      swal.fire('Data Berhasil diproses', response.data.message, 'success')
    }

    return response
  },
  error => {
    store.commit('setLoading', false)

    // const { status, data } = error.response

    // if (status === 401) {
    //   swal.fire({
    //     type: 'warning',
    //     title: 'Sesi Berahir',
    //     text: 'Sesi Anda Telah Berahir, Silahkan Login Lagi!!!'
    //   })
    //     .then(() => {
    //       location.reload()
    //     })
    // } else {
    //   swal.fire(
    //     'Oopss! Error ' + status,
    //     data.message,
    //     'error'
    //   )
    // }

    // this.$swal.fire({
    //   position: "bottom-end",
    //   icon: "error",
    //   title: "Error Ketika Meminta Data Toko",
    //   showConfirmButton: false,
    //   timer: 3000
    // })

    const { status, data } = error.response
    if (status === 401) {
      store.dispatch('auth/clearUser')
    } else if (status == 503){
      router.push('/maintenance')
    } else {
      if (status !== 422) {
        swal.fire(
          'Oopss! Error ' + status,
          data.message,
          'error'
        )
      }
    }

    return Promise.reject(error)
  }
)

export default instance
