import axios from '@/plugins/axios'
import { savePost } from '../utils'

export default {
  index(query = '') {
    return axios.get(`/admin/orders${query}`)
  },
  details(orderId) {
    return axios.get(`/admin/orders/${orderId}/details`)
  },
  shippings(query = '') {
    return axios.get(`/admin/orders/shipping-costs${query}`)
  },
  verifyShipping(orderId) {
    return savePost(`/admin/orders/${orderId}/verify-shipping-cost`)
  },
  changePurchaseStatus(orderId, data) {
    return savePost(`/admin/orders/${orderId}/change-purchase-status`, data, 'put')
  },
  changeBillStatus(orderId, data) {
    return savePost(`/admin/orders/${orderId}/change-bill-status`, data, 'put')
  },
  changeSchoolTax(orderId, data) {
    return savePost(`/admin/orders/${orderId}/change-tax-data`, data)
  },
  historiesByOrder(orderId) {
    return axios.get(`/admin/orders/${orderId}/histories`)
  },
  processReturning(orderId) {
    return savePost('/admin/orders/process-returning', {order_id: orderId})
  },
  closed(orderId, data) {
    return savePost(`/admin/orders/${orderId}/close`, data, 'postForm')
  },
  loan(orderId, amount) {
    return savePost(`/admin/orders/${orderId}/loan`, { amount }, 'postForm')
  },

  // close order
  closing(query = '') {
    return axios.get(`/admin/close-orders${query}`)
  },
  closingAction(closeOrderId, type, payload) {
    return savePost(`/admin/close-orders/${closeOrderId}/${type}`, payload)
  },
}
