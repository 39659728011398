import axios from '@/plugins/axios'
import { savePost } from '../utils'

export default {
  index (query = '') {
    return axios.get(`/admin/branches${query}`)
  },
  sendData(form) {
    if (!form.id) {
      return savePost('/admin/branches', form)
    } else {
      return savePost(`/admin/branches/${form.id}?_method=put`, form)
    }
  }
}
