import axios from '@/plugins/axios'
import { savePost } from '../utils'

export default {
  maintenance() {
    return axios.get('/ping')
  },
  getSystemBanks () {
    return axios.get('/public/system-banks')
  },
  getBanks () {
    return axios.get('/public/banks')
  },
  async getLocalSystemBanks () {
    let systemBanks;
    const localSystemBanks = window.sessionStorage.getItem('system_banks')
    if (localSystemBanks) {
      systemBanks = JSON.parse(localSystemBanks)
    } else {
      const res = await this.getSystemBanks()
      if (res.data) {
        systemBanks = res.data
        window.sessionStorage.setItem('system_banks', JSON.stringify(res.data))
      }
    }
    return systemBanks
  },
  async getLocalBanks () {
    let banks;
    const localBanks = window.sessionStorage.getItem('banks')
    if (localBanks) {
      banks = JSON.parse(localBanks)
    } else {
      const res = await this.getBanks()
      if (res.data) {
        banks = res.data
        window.sessionStorage.setItem('banks', JSON.stringify(res.data))
      }
    }
    return banks
  },
  getSettings () {
    return axios.get('/admin/settings')
  },
  setSetting (id, payload) {
    if (['object','array','collection'].includes(payload.type)) {
      payload.content = JSON.parse(payload.content)
    }
    return savePost(`/admin/settings/${id}`, payload, 'put')
  },
  async getPaymentMethods () {
    const localPaymentMethods = window.sessionStorage.getItem('payment_methods')
    if (localPaymentMethods) {
      return JSON.parse(localPaymentMethods)
    }

    const res = await axios.get('/public/payment-methods?active=0&bank=1')
    const data = res.data.sort((a,b) => a.id - b.id)
    window.sessionStorage.setItem('payment_methods', JSON.stringify(data))

    return data
  },

  async getPpnTags () {
    const localPpnTags = window.sessionStorage.getItem('ppn_tags')
    if (localPpnTags) {
      return JSON.parse(localPpnTags)
    }

    const res = await axios.get('/public/ppn/items')
    const data = res.data
    window.sessionStorage.setItem('payment_methods', JSON.stringify(data))

    return data
  },

  getProvinces () {
    return axios.get('/public/provinces')
  },
  getCities (provinceId) {
    return axios.get(`/public/cities/${provinceId}`)
  },
  getDistricts (cityId) {
    return axios.get(`/public/districts/${cityId}`)
  },
  uploadFile(file, type) {
    let body = new FormData
    body.append('file', file)
    body.append('type', type)

    return axios.post('/admin/upload', body)
  },
}
