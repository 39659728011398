import axios from '@/plugins/axios'
import { savePost } from '../utils'

export default {
  users (query = '') {
    return axios.get(`/admin/admins${query}`)
  },
  newUser(data) {
    return savePost(`/admin/admins`, data)
  },
  updateRoles(adminId, roles) {
    return savePost(`/admin/admins/${adminId}/update-roles`, roles)
  },
  updateActive(adminId, active) {
    return savePost(`/admin/admins/${adminId}/update-active`, {active})
  }
}
