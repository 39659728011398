import axios from '@/plugins/axios'
import { savePost } from '../utils'

export default {
  index(query = '') {
    return axios.get(`/admin/products${query}`)
  },
  details(productId) {
    return axios.get(`/admin/products/${productId}`)
  },
  verify(payload) {
    return savePost(`/admin/products/verify`, payload)
  },
  suspend (payload) {
    return savePost(`/admin/products/suspend`, payload)
  },
  bulkSuspend(data) {
    return savePost(`/admin/products/bulk-suspend`, data)
  },
  checkBulkSuspend() {
    return axios.get('/admin/products/bulk-suspend')
  },
  unsuspend (payload) {
    return savePost(`/admin/products/unsuspend`, payload)
  }
}
