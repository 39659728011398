import axios from '@/plugins/axios'
import { savePost } from '../utils'

export default {
  index (parent) {
    return axios.get(`/admin/product-categories?parent=${parent}`)
  },
  sendData(form) {
    let body = new FormData
    body.append('name', form.data.name)
    if (form.data.parent.id) body.append('parent', form.data.parent.id)
    body.append('sequence', form.data.sequence)
    if (form.image) body.append('image', form.image)
    body.append('enabled', form.data.enabled)

    if (form.isNew) {
      return savePost('/admin/product-categories', body)
    } else {
      return savePost(`/admin/product-categories/${form.data.id}?_method=put`, body)
    }
  }
}
