<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <!-- <div class="row mb-2">
            <div class="col-sm-8">
              <button type="button" class="btn btn-primary"
                @click="addCategory(parent)">
                Tambah Kategori
              </button>
            </div>
          -->
          <b-table
              :items="categories"
              primary-key="id"
              :fields="fields"
              responsive
              stacked="lg"
          >
            <template #cell(name)="data">
              <!-- <div style="cursor: pointer;" @click="editCategory(data.item)">
                {{ data.value }}
              </div> -->
              <div>
                {{ data.value }}
              </div>
            </template>
            <template #cell(enabled)="data">
              <div class="text-center">
                <span class="badge font-size-12 p-2 badge-success" v-if="data.value">
                  Aktif
                </span>
                <span class="badge font-size-12 p-2 badge-warning" v-else>
                  Non Aktif
                </span>
              </div>
            </template>
            <template #cell(v_action)="row">
              <div class="text-center">
                <b-button size="sm" variant="white" v-if="row.item.id != 0" @click="openDetails(row)">
                  <i class="mdi mdi-dots-horizontal font-size-18" />
                </b-button>
              </div>
            </template>
            <template #row-details="row">
              <TableCategory
                :parent="row.item"
                :categories="row.item.children"
              />
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '@/api'

export default {
  name: 'TableCategory',
  props: ['parent', 'categories'],
  data() {
    return {
      fields: [
        { key: 'name', label: 'Nama Kategori' },
        { key: 'sequence', label: 'No Urut' },
        { key: 'enabled', label: 'Status', thClass: 'text-center' },
        { key: 'v_action', label: 'Aksi', thClass: 'text-center' },
      ],
      datas: []
    }
  },
  methods: {
    addCategory(parent) {
      this.$emit('addCategory', parent)
    },
    openDetails(row) {
      if (row.item.children) {
        row.toggleDetails()
        return
      }
      Api.category.index(row.item.id).then(res => {
        if (res.data) {
          row.item.children = res.data
          row.toggleDetails()
        }
      })
    }
  }
}
</script>
