import axios from '@/plugins/axios'
import { savePost } from '../utils'

export default {
  index (query = '') {
    return axios.get(`/admin/merchants${query}`)
  },
  updateIndex (startingAt = '-1') {
    return axios.get(`/admin/merchants/updates?starting_at=${startingAt}`)
  },
  updateShow (merchantId) {
    return axios.get(`/admin/merchants/${merchantId}/update`)
  },
  updateData (payload) {
    return savePost(`/admin/merchants/update`, payload, 'patch')
  },
  updateProcess (merchant_id, status, note) {
    return savePost(`/admin/merchants/process-update`, {merchant_id, status, note})
  },
  details(merchantId) {
    return axios.get(`/admin/merchants/${merchantId}`)
  },
  simpleDetail(merchantId) {
    return axios.get(`/admin/merchants/${merchantId}/simple`)
  },
  products (merchantId, query = '') {
    return axios.get(`/admin/merchants/${merchantId}/products${query}`)
  },
  orders (merchantId, query = '') {
    return axios.get(`/admin/merchants/${merchantId}/orders${query}`)
  },
  verify(payload) {
    return savePost(`/admin/merchants/verify`, payload)
  },
  suspend (payload) {
    return savePost(`/admin/merchants/suspend`, payload)
  },
  unsuspend (payload) {
    return savePost(`/admin/merchants/unsuspend`, payload)
  },
  impersonate (userId) {
    return axios.post('/admin/impersonate/seller', {user_id: userId})
  },
  addBranch (merchantId, payload) {
    return savePost(`/admin/merchants/${merchantId}/branch`, payload)
  },
  // tokla products
  premium (query = '') {
    return axios.get(`/admin/premium-merchants${query}`)
  },
  verifyPremium (payload) {
    return savePost(`/admin/premium-merchants/verify`, payload)
  },
  markPremium (payload) {
    return savePost(`/admin/premium-merchants/mark`, payload)
  },
  toklaProducts (query = '') {
    return axios.get(`/admin/tokla-products${query}`)
  },
  saveToklaProduct (payload) {
    const _method = payload.product_id ? 'put' : 'post'
    return savePost('/admin/tokla-products', payload, _method)
  },
}
