import axios from '@/plugins/axios'
import { savePost } from '../utils'

export default {
  users (query = '') {
    return axios.get(`/admin/inspectors${query}`)
  },
  impersonate (userId) {
    return axios.post('/admin/impersonate/inspector', {user_id: userId})
  },
  newUser(data) {
    return savePost(`/admin/inspectors`, data)
  },
  updateRoles(adminId, roles) {
    return savePost(`/admin/inspectors/${adminId}/update-roles`, roles)
  },
  updateActive(adminId, active) {
    return savePost(`/admin/inspectors/${adminId}/update-active`, {active})
  }
}
