import axios from '@/plugins/axios'

export default {
  index() {
    return axios.get(`/admin/dashboard/index`)
  },
  getTransactions(orderDate = '', source = '', location = '') {
    return axios.get(`/admin/dashboard/transactions?order_date=${orderDate}&source=${source}&location=${location}`)
  },
  getSchools(zone = '', location = '') {
    return axios.get(`/admin/dashboard/schools?zone=${zone}&location=${location}`)
  },
  getMerchants(orderDate = '', status = '', location = '') {
    return axios.get(`/admin/dashboard/merchants?order_date=${orderDate}&status=${status}&location=${location}`)
  },
  getProducts(status = '') {
    return axios.get(`/admin/dashboard/products?status=${status}`)
  },
  getLogActivities(query = '') {
    return axios.get(`/admin/dashboard/log-activities${query}`)
  },
}
