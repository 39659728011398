import axios from '@/plugins/axios'
import Swal from 'sweetalert2'

export const savePost = (url, data = null, method = 'post') => {
  return new Promise((resolve) => {
    Swal.fire({
      title: 'Apakah Anda Akan Memproses Data ini?',
      text: "Anda Tidak Dapat Membatalkan Tindakan ini!",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Batalkan',
      confirmButtonText: 'Iya, Simpan Data',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
    }).then((result) => {
      if (result.isConfirmed) {
        resolve(axios[method](url, data))
      }
    })
  })
}
