import axios from '@/plugins/axios'
import { savePost } from '../utils'

export default {
  index (query = '') {
    return axios.get(`/admin/schools${query}`)
  },
  details(schoolId) {
    return axios.get(`/admin/schools/${schoolId}`)
  },
  suspend (payload) {
    return savePost(`/admin/schools/suspend`, payload)
  },
  unsuspend (payload) {
    return savePost(`/admin/schools/unsuspend`, payload)
  },
  impersonate (userId) {
    return axios.post('/admin/impersonate/buyer', {user_id: userId})
  },
}
