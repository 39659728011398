// state
export const state = {
  menuCondensed: false,
  isLoading: false,
}

// getters
export const getters = {}

// mutations
export const mutations = {
  toggleMenu(state) {
    state.menuCondensed = ! state.menuCondensed
    document.body.classList.remove("sidebar-enable")
    document.body.classList.remove("vertical-collpsed")
    if (state.menuCondensed) {
      document.body.classList.add("vertical-collpsed")
    } else {
      document.body.classList.add("sidebar-enable")
    }
  },
  setLoading(state, payload) {
    state.isLoading = payload
  }
}

// actions
export const actions = {}
