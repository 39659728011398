import axios from '@/plugins/axios'
import { savePost } from '../utils'

export default {
  index (query = '') {
    return axios.get(`/admin/payments${query}`)
  },
  summary () {
    return axios.get('/admin/payments/summary')
  },
  verifyData(orderId, data) {
    return savePost(`/admin/payments/${orderId}/verify`, data)
  },
  reverifyData(orderId, data) {
    return savePost(`/admin/payments/${orderId}/reverify`, data)
  },
  validateBiartha(orderId) {
    return savePost('/admin/payments/biartha', {order_id: orderId})
  },
  handlingFees(orderId, data) {
    return savePost(`/admin/payments/${orderId}/handling-fees`, data)
  },
  forwardPayment(orderId, data) {
    return savePost(`/admin/payments/${orderId}/forward`, data)
  },
  bulkForwardPayment(data) {
    return savePost(`/admin/payments/bulk-forward`, data)
  },
  checkBulkForward() {
    return axios.get('/admin/payments/bulk-forward')
  },
  bankMutations (mutationIn, mutationOut) {
    return axios.get(`/admin/payments/bank-mutations?in=${mutationIn}&out=${mutationOut}`)
  },
  checkVaByMethod (method, va) {
    return axios.post('/admin/payments/check-va', {method, va})
  },
  updateVaByMethod (data) {
    return savePost('/admin/payments/update-va', data)
  },
  flagVaByMethod (data) {
    return savePost('/admin/payments/flag-va', data)
  }
}
