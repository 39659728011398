import axios from '@/plugins/axios'
import { savePost } from '../utils'

export default {
  index (query = '') {
    return axios.get(`/admin/complains${query}`)
  },
  details(complainId) {
    return axios.get(`/admin/complains/${complainId}`)
  },
  reply(complainId, message) {
    return savePost(`/admin/complains/${complainId}/reply`, { message })
  },
  close(complainId, message) {
    return savePost(`/admin/complains/${complainId}/close`, { message })
  },
  escalate(complainId, message) {
    return savePost(`/admin/complains/${complainId}/escalate`, { message })
  },
}
