import axios from '@/plugins/axios'
import store from '@/store'

export default {
  async login (payload) {
    await axios.get('public/csrf-cookie')
    return axios.post('/admin/login', payload)
  },
  check () {
    return axios.get('/admin/check')
  },
  async logout () {
    await axios.post('/admin/logout')
    store.dispatch('auth/clearUser')
  },
  forgotPassword(email) {
    return axios.post('/admin/password/email', email)
  },
  resetPassword(data) {
    return axios.post('/admin/password/reset', data)
  },
  verifyEmail(id, hash, query) {
    return axios.get(`/admin/admin-email/verify/${id}/${hash}?${query}`)
  },
  resendEmailVerify() {
    return axios.post('/admin/admin-email/verification-notification')
  }
}
