export default [
  {
    path: '/',
    redirect: '/dashboard',
  },
  {
    path: '/dashboard',
    name: 'home',
    meta: {
      guard: 'auth',
    },
    component: () => import('./views/home'),
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: () => import('./views/maintenance'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('./views/account/login'),
    meta: {
      guard: 'guest',
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('./views/account/forgot-password'),
    meta: {
      guard: 'guest',
    },
  },
  {
    path: '/password/reset',
    name: 'password-reset',
    component: () => import('./views/account/reset'),
    meta: {
      guard: 'guest',
    },
  },
  {
    path: '/admin-email/verify',
    name: 'verify-email',
    component: () => import('./views/account/verify'),
    meta: {
      guard: 'auth',
    },
  },
  {
    path: '/404',
    name: '404',
    component: require('./views/errors/404').default,
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: '*',
    redirect: '404',
  },
  {
    path: '/pages/500',
    name: 'Error-500',
    meta: { guard: 'auth' },
    component: () => import('./views/errors/500')
  },
  // Orders
  {
    path: '/orders',
    name: 'orders',
    meta: { guard: 'auth' },
    component: () => import('./views/orders/index')
  },
  {
    path: '/shippings',
    name: 'shippings',
    meta: { guard: 'auth' },
    component: () => import('./views/shippings/index')
  },
  {
    path: '/orders/:orderId',
    name: 'orders-details',
    meta: { guard: 'auth' },
    component: () => import('./views/orders/details')
  },
  {
    path: '/closing',
    name: 'closing',
    meta: { guard: 'auth' },
    component: () => import('./views/orders-closing/index')
  },
  // Schools
  {
    path: '/schools',
    name: 'schools',
    meta: { guard: 'auth:staff' },
    component: () => import('./views/schools/index')
  },
  {
    path: '/schools/:schoolId',
    name: 'schools-details',
    meta: { guard: 'auth:staff' },
    component: () => import('./views/schools/details')
  },
  // Merchants
  {
    path: '/merchants',
    name: 'merchants',
    meta: { guard: 'auth:staff' },
    component: () => import('./views/merchants/index')
  },
  {
    path: '/merchants/update',
    name: 'merchants-update',
    meta: { guard: 'auth:staff' },
    component: () => import('./views/merchants/update')
  },
  {
    path: '/merchants/:merchantId',
    name: 'merchants-details',
    meta: { guard: 'auth:staff' },
    component: () => import('./views/merchants/details')
  },
  // premium merchants
  {
    path: '/premium-merchants',
    name: 'premium-merchants',
    meta: { guard: 'auth:finance' },
    component: () => import('./views/merchants/premium/index')
  },
  {
    path: '/premium-merchants/products',
    name: 'products-premium-merchants',
    meta: { guard: 'auth:finance' },
    component: () => import('./views/merchants/premium/product')
  },
  // {
  //   path: '/merchants/:merchantId/payments',
  //   name: 'merchant-payments',
  //   meta: { guard: 'auth:finance'},
  //   component: () => import('./views/merchants/payments')
  // },
  {
    path: '/merchants/:merchantId/products',
    name: 'merchants-products',
    meta: { guard: 'auth:staff'},
    component: () => import('./views/products/index')
  },
  {
    path: '/merchants/:merchantId/orders',
    name: 'merchants-orders',
    meta: { guard: 'auth:staff'},
    component: () => import('./views/orders/index')
  },

  // Products
  {
    path: '/products',
    name: 'products',
    meta: { guard: 'auth:staff' },
    component: () => import('./views/products/index')
  },
  {
    path: '/products/:productId',
    name: 'products-details',
    meta: { guard: 'auth:staff' },
    component: () => import('./views/products/details')
  },
  // payments
  {
    path: '/payments',
    name: 'payments',
    meta: { guard: 'auth:finance' },
    component: () => import('./views/payments/index')
  },
  // complains
  {
    path: '/complains',
    name: 'complains',
    meta: { guard: 'auth' },
    component: () => import('./views/complains/index')
  },
  // Admin Auth
  {
    path: '/users',
    name: 'users',
    meta: { guard: 'auth:admin' },
    component: () => import('./views/admin/users')
  },
  {
    path: '/inspectors',
    name: 'inspectors',
    meta: { guard: 'auth:admin' },
    component: () => import('./views/admin/inspectors')
  },
  {
    path: '/branches',
    name: 'branches',
    meta: { guard: 'auth:admin' },
    component: () => import('./views/admin/branch')
  },
  {
    path: '/promos',
    name: 'promos',
    meta: { guard: 'auth:admin' },
    component: () => import('./views/admin/promo')
  },
  {
    path: '/product-categories',
    name: 'product-categories',
    meta: { guard: 'auth:admin' },
    component: () => import('./views/admin/category')
  },
  {
    path: '/settings',
    name: 'settings',
    meta: { guard: 'auth:admin' },
    component: () => import('./views/admin/setting')
  },
  {
    path: '/log-activities',
    name: 'log-activities',
    meta: { guard: 'auth:admin' },
    component: () => import('./views/admin/user-activity')
  },
]
